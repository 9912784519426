<template>
    <v-row no-gutters class="fill-height flex-column" justify="space-around" align="center">
        <div>
            <div class="under-construction text-capitalize">
                Under construction
            </div>
            <v-img class="mx-auto mt-12"
                   src="../assets/under-construction.svg"
                   max-width="158"
                   max-height="133"
            />
        </div>
    </v-row>
</template>

<script>
    export default {
        name: "empty-view"
    }
</script>

<style scoped>
    .under-construction {
        color: #364462;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 38px;
    }
</style>
